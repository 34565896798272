import { Component } from '@angular/core';

@Component({
  selector: 'app-public-layout-body',
  templateUrl: './public-layout-body.component.html',
  styleUrl: './public-layout-body.component.scss'
})
export class PublicLayoutBodyComponent {

}
