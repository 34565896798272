import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';
import { I18nServiceService } from 'src/app/shared/shared-services/i18n-service/i18n-service.service';

@Component({
  selector: 'app-marketing-header',
  templateUrl: './marketing-header.component.html',
  styleUrls: ['./marketing-header.component.css'],
})
export class MarketingHeaderComponent implements OnInit {
  private viewportWidth: number;
  public sessionUser: string = '';
  public searchResult: string = 'Search result : ';
  public searchValue: string = '';
  public resultType: string = 'Article/News';
  //public searchResults = ['Brac', 'Brac', 'Brac', 'Brac', 'Brac'];
  public isModalOpen: boolean = false;
  public searchResults: {
    title: string;
    topic: string;
    id: string;
    type: string;
  }[] = [];

  public isLoggedIn: boolean = false;
  public showHamburger: boolean = false;
  public isMobileView: boolean = false;
  public showLangBtn: boolean = true;
  public verticalNavbar: boolean = false;
  public showSearchModal: boolean = false;

  public profileSettings: any[] = [
    { value: 1, name: 'Change Password', icon: 'edit' },
    { value: 2, name: 'Log Out', icon: 'runner' },
  ];

  constructor(
    public readonly router: Router,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
    private readonly translate: TranslateService,
    private readonly i18nService: I18nServiceService,
    private readonly shohayBlogService: ShohayBlogService
  ) {
    this.viewportWidth = window.innerWidth;
    this.showHamburger = this.viewportWidth < 1200 ? true : false;
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.viewportWidth = window.innerWidth;
    this.showHamburger = this.viewportWidth < 1323 ? true : false;
    this.showLangBtn = this.viewportWidth < 745 ? false : true;
    this.isMobileView = this.viewportWidth < 655 ? true : false;
    if (!this.showHamburger) {
      this.verticalNavbar = false;
    }
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.sessionUser = this.localStorageService.getSessionUser().name;
      this.isLoggedIn = true;
    }

    if (window.innerWidth < 455) {
      this.isMobileView = true;
    }
    this.i18nService.localeEvent.subscribe((lang) => this.translate.use(lang));
  }

  profileDropDownButtonClick(e: any): void {
    console.log(e);
    if (e.itemData.value === 1) {
      this.router.navigateByUrl('/auth/change-password');
    } else {
      this.authService.logout();
      this.isLoggedIn = false;
      this.sessionUser = '';
    }
  }

  searchModal(value: boolean): void {
    this.showSearchModal = value;
    if (value === true) {
      this.isModalOpen = true;
      this.shohayBlogService.getArticleNews().subscribe((result) => {
        console.log(result);
        this.searchResults = result.map((element: any) => {
          return {
            title: element?.title,
            topic: element?.topic,
            id: element?.id,
            type: element?.type,
          };
        });
      });
    } else {
      this.isModalOpen = false;
    }
  }

  get filteredResults(): {
    title: string;
    topic: string;
    id: string;
    type: string;
  }[] {
    return this.searchResults.filter(
      (item) =>
        item.title.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        item.topic.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }

  public seeAllResult(): void {
    this.showSearchModal = false;
    this.router.navigateByUrl('/search-blog');
  }

  // goToNgoPage(ngoName: string): void {
  //   this.showSearchModal = false;
  //   const queryParams = { name: JSON.stringify({ name: ngoName }) };
  //   this.router.navigate(['/ngo'], { queryParams });
  // }

  public goToHomePage(): void {
    this.router.navigate(['/']);
  }

  public showVerticalNavbar(): void {
    this.verticalNavbar = !this.verticalNavbar;
  }

  // searchButton: DxButtonTypes.Properties = {
  //   icon: '/assets/images/icon/find-icon.svg',
  //   stylingMode: 'text',
  // };

  public changeLang(language: string): void {
    this.i18nService.changeLanguage(language);
  }

  public getText(): string {
    if (this.isLoggedIn) {
      return this.translate.instant('Header.Upgrade');
    } else {
      return this.translate.instant('Header.Contact');
    }
  }

  public logOut(): void {
    this.authService.logout();
    this.isLoggedIn = false;
    this.router.navigateByUrl('/');
  }

  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/find_icon.svg',
    stylingMode: 'text',
  };

  public goToArticleNews(id: string, type: string): void {
    console.log(type);
    if (type === 'Article') {
      this.router.navigate(['/article', id]);
      this.searchModal(false);
    } else {
      this.router.navigate(['/news', id]);
      this.searchModal(false);
    }
  }
}
