<div
  class="notification overflow-hidden"
  [style.display]="isMobileView ? 'none' : 'notification'"
>
  <p class="notif">
    {{ "Notification.notify" | translate }}
    <span>
      <a href="" class="not-link"
        >{{ "Notification.learn-more" | translate }}
        <img
          style="margin-left: 4px; width: 15px"
          src="./assets/images/icon/arrow_right_icon.svg"
      /></a>
    </span>
  </p>
</div>

<div id="navBar" class="nav-div sticky-top">
  <div class="d-flex">
    <div class="align-self-center">
      <img
        style="cursor: pointer"
        src="./assets/images/logo/shohay_logo_full.svg"
        (click)="goToHomePage()"
        [style.height]="isMobileView ? '25px' : 'auto'"
        [style.width]="isMobileView ? '100px' : 'auto'"
      />
    </div>
    <nav
      class="navbar navbar-expand-sm bg-white navbar-light p-0"
      *ngIf="!showHamburger"
    >
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a
                class="nav-link nav-li-text dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                >{{ "Header.Products" | translate }}</a
              >
              <ul class="dropdown-menu">
                <li><a class="dropdown-item nav-li-text" href="#">Link</a></li>
                <li>
                  <a class="dropdown-item nav-li-text" href="#">Another link</a>
                </li>
                <li>
                  <a class="dropdown-item nav-li-text" href="#">A third link</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-li-text" [routerLink]="['/find-ngo']">{{
                "Header.Find-Ngo" | translate
              }}</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link nav-li-text"
                [routerLink]="['/dashboard/manage-my-ngo']"
                >{{ "Header.start-ngo" | translate }}</a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link nav-li-text dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                >{{ "Header.Resources" | translate }}</a
              >
              <ul class="dropdown-menu">
                <li><a class="dropdown-item nav-li-text" href="#">Link</a></li>
                <li>
                  <a class="dropdown-item nav-li-text" href="#">Another link</a>
                </li>
                <li>
                  <a class="dropdown-item nav-li-text" href="#">A third link</a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-li-text" href="#">{{
                "Header.Corporate" | translate
              }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-li-text" href="#">{{
                "Header.About" | translate
              }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-li-text" href="#">{{
                "Header.Support" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <div
    class="d-flex nav-right-div"
    [style.width]="isMobileView ? '100%' : 'auto'"
    [style.justifyContent]="isMobileView ? 'flex-end' : 'flex-start'"
  >
    <div class="d-flex">
      <img
        *ngIf="showLangBtn"
        src="/assets/images/icon/find_icon.svg"
        class="find-icon"
        alt="Search"
        title="Search"
        onmouseover="this.style='width: 25px;'"
        onmouseout="this.style='find-icon'"
        (click)="searchModal(true)"
      />
      <select
        #lang
        class="nav-lang"
        name=""
        id=""
        *ngIf="!isMobileView"
        (change)="changeLang(lang.value)"
      >
        <!-- (change)="changeLanguage($event)" -->
        <!-- *ngFor="let i of languageOptions" [value]="i.value" -->
        <!-- {{ i.label | translate }} -->
        <option value="en">{{ "Header.en" | translate }}</option>
        <option value="bn">{{ "Header.bn" | translate }}</option>
      </select>

      <div class="button-group">
        <div *ngIf="isLoggedIn" class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle custom-dropdown-toggle username_button"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              style="margin-bottom: 4px"
              src="./assets/images/icon/profile_icon.svg"
            />
            {{ sessionUser }}
          </button>
          <ul
            class="dropdown-menu dropdown_menu"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <a class="dropdown-item pointer"
                ><img
                  class="c"
                  src="./assets/images/icon/profile_icon_black.svg"
                />
                My Profile</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a class="dropdown-item pointer"
                ><img
                  class="c"
                  src="./assets/images/icon/settings_icon_black.svg"
                />
                Account Setting</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a class="dropdown-item" [routerLink]="'/auth/change-password'"
                ><img
                  class="c"
                  src="./assets/images/icon/lock_icon_black.svg"
                />
                Change Password</a
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <a class="dropdown-item pointer" (click)="logOut()"
                ><img
                  class="c"
                  src="./assets/images/icon/logout_icon_black.svg"
                />
                Logout</a
              >
            </li>
            <!-- <li><a class="dropdown-item" href="#">Something else here</a></li>    -->
            <!-- here -->
          </ul>
        </div>
        <button
          *ngIf="!isMobileView && !isLoggedIn"
          class="yellow-button-md"
          [routerLink]="['/auth/login']"
          [style.display]="isMobileView ? 'none' : 'yellow-button-md'"
        >
          {{ "Header.Login" | translate }}
          <!-- Login -->
        </button>
        <button
          *ngIf="!isMobileView"
          class="blue-button-md"
          [style.height.px]="isMobileView ? 32 : 50"
          [style.width]="isMobileView ? '123px' : 'auto'"
          [style.fontSize.px]="isMobileView ? 9 : 16"
          [innerText]="getText()"
        >
          <!-- [innerText]="
            isLoggedIn ? 'Upgrade to Pro Version' : 'Contact with Sale'
          " -->
        </button>
      </div>
      <span
        class="hamburger"
        *ngIf="showHamburger"
        (click)="showVerticalNavbar()"
      >
        <img src="/assets/images/icon/menu_icon.svg" style="width: 30px"
      /></span>
    </div>
  </div>
</div>

<nav
  class="navbar bg-white vertical-navbar"
  style="padding-inline: 0px !important; height: 550px"
  *ngIf="showHamburger && verticalNavbar"
>
  <div
    class="container-fluid"
    style="justify-content: end; height: 500px; align-content: space-between"
  >
    <ul class="navbar-nav" style="width: -webkit-fill-available">
      <form *ngIf="!showLangBtn" class="d-flex" role="search">
        <input
          class="form-control"
          style="margin-bottom: 10px"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
      </form>
      <select
        *ngIf="!showLangBtn"
        class="nav-lang"
        style="
          margin-right: 0px;
          height: 35px;
          border-bottom: 1px solid #bebebe;
        "
        name=""
        id=""
      >
        <option value="">EN</option>
        <option value="">BN</option>
      </select>
      <li class="nav-item text-right-end dropdown">
        <a
          class="nav-link nav-li-text dropdown-toggle dropdown-left border_block"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          >Products</a
        >
        <ul class="dropdown-menu border-0">
          <li>
            <a class="dropdown-item dropdown_text_right" href="#">Link</a>
          </li>
          <li>
            <a class="dropdown-item dropdown_text_right" href="#"
              >Another link</a
            >
          </li>
          <li>
            <a class="dropdown-item dropdown_text_right" href="#"
              >A third link</a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item text-right-end">
        <a
          class="nav-link nav-li-text border_block"
          [routerLink]="['/find-ngo']"
          >Find an NGO</a
        >
      </li>
      <li class="nav-item text-right-end">
        <a
          class="nav-link nav-li-text border_block"
          routerLink="/dashboard/manage-my-ngo"
          >Start/Manage NGO</a
        >
      </li>
      <li class="nav-item text-right-end dropdown">
        <a
          class="nav-link nav-li-text dropdown-toggle dropdown-left border_block"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          >Resources</a
        >
        <ul class="dropdown-menu border-0">
          <li>
            <a class="dropdown-item dropdown_text_right" href="#">Link</a>
          </li>
          <li>
            <a class="dropdown-item dropdown_text_right" href="#"
              >Another link</a
            >
          </li>
          <li>
            <a class="dropdown-item dropdown_text_right" href="#"
              >A third link</a
            >
          </li>
        </ul>
      </li>
      <li class="nav-item text-right-end">
        <a class="nav-link nav-li-text border_block" href="#">Corporate</a>
      </li>
      <li class="nav-item text-right-end">
        <a class="nav-link nav-li-text border_block" href="#">About</a>
      </li>
      <li class="nav-item text-right-end">
        <a class="nav-link nav-li-text border_block" href="#">Support</a>
      </li>
    </ul>
    <form
      *ngIf="isMobileView"
      class="d-flex flex-column"
      style="margin-top: 16px; gap: 16px; width: 100%"
    >
      <button
        class="blue-button-md"
        type="submit"
        [innerText]="
          isLoggedIn ? 'Upgrade to Pro Version' : 'Contact with Sale'
        "
      ></button>
      <button
        class="yellow-button-md"
        style="margin-right: 0px"
        (click)="isLoggedIn ? logOut() : router.navigateByUrl('/auth/login')"
      >
        {{ isLoggedIn ? "Log out" : "Register/Log in" }}
      </button>
    </form>
  </div>
</nav>

<div *ngIf="showSearchModal" class="modal-background">
  <div
    class="modal-backdrop"
    *ngIf="isModalOpen"
    (click)="searchModal(false)"
  ></div>
  <div class="modal">
    <div class="w-100 d-flex justify-content-center align-items-center">
      <!-- <dx-text-box
        class="searchbox"
        placeholder="Search articles or news"
        [inputAttr]="{ 'aria-label': 'text' }"
        stylingMode="filled"
        [showClearButton]="true"
        [spellcheck]="true"
      >
      </dx-text-box>
      <dx-button
        class="cancel-button"
        text="Cancel"
        (onClick)="searchModal(false)"
      >
      </dx-button> -->
      <!-- [(ngModel)]="ngoSearchQuery" -->
      <dx-text-box
        class="search_box"
        placeholder="Search article or news"
        stylingMode="filled"
        [showClearButton]="true"
        [(ngModel)]="searchValue"
      >
        <!-- (onEnterKey)="onSearchClick()" -->
        <!-- <dxi-button
          name="searchIcon"
          [options]="searchIcon"
          location="before"
        ></dxi-button> -->
      </dx-text-box>
      <div class="cancel" (click)="searchModal(false)">Cancel</div>
    </div>
    <div class="search-result-row">
      {{ searchResult }}
      <span class="search-text">{{ searchValue }}</span>
    </div>
    <div class="last-search-result-row">
      <div class="article-news">
        <div style="display: flex; margin-bottom: 8px">
          {{ resultType }}
        </div>
        <div class="scrollable-list">
          <div *ngFor="let item of filteredResults">
            <div
              class="list-item"
              (click)="goToArticleNews(item.id, item.type)"
            >
              <span class="list-item-first-line">{{ item.title }}</span>
              <span style="line-height: 24px">{{ item.topic }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="all-results-link" (click)="seeAllResult()">
        See all results
      </div>
    </div>
  </div>
</div>
