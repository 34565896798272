import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DevExtremeModule } from './shared/modules/devExtreme.module';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MarketingFooterComponent } from './layout/marketing-layout/marketing-footer/marketing-footer.component';
import { MarketingHeaderComponent } from './layout/marketing-layout/marketing-header/marketing-header.component';
import { MarketingLayoutBodyComponent } from './layout/marketing-layout/marketing-layout-body/marketing-layout-body.component';
import { CookiePermissionComponent } from './shared/components/cookie-permission/cookie-permission.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { TokenInterceptor } from './shared/interceptor/token/token.interceptor';
import { SharedModule } from './shared/modules/shared.module';
import { HttpRequestService } from './shared/services/http-request/http-request.service';
import { DxDropDownButtonModule } from 'devextreme-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MarketingModule } from './modules/marketing/marketing.module';
import { AuthService } from './shared/services/auth/auth.service';
import { PublicLayoutBodyComponent } from './layout/public-layout/public-layout-body/public-layout-body.component';
import { NgOptimizedImage } from '@angular/common';
import { SuperAdminModule } from './modules/super-admin/super-admin.module';

//this will create issues

// function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

function HttpLoaderFactory(http: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(http));
}

const I18N_CONFIG = {
  // defaultLanguage: 'english',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    // deps: [HttpClient],
    deps: [HttpBackend],
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    MarketingHeaderComponent,
    MarketingFooterComponent,
    MarketingLayoutBodyComponent,
    CookiePermissionComponent,
    PublicLayoutBodyComponent,
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    AppRoutingModule,
    DevExtremeModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    DxDropDownButtonModule,
    TranslateModule.forRoot(I18N_CONFIG),
    MarketingModule,
    NgOptimizedImage,
    SuperAdminModule
  ],
  providers: [
    HttpRequestService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
