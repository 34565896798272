<div *ngIf="isShowCookieModal" class="cookie-permission-wrapper">
  <div class="cookie-title">
    <img
      width="28px"
      height="28px"
      src="../../../../assets/images/icon/cookie.png"
      alt="cookie"
    />
    <div>Cookie Consent</div>
  </div>
  <div class="cookie-description">
    By clicking “Accept Cookies”, you agree Shohay can store cookies on your
    device and disclose information in accordance with our Cookie Policy.
  </div>
  <div class="cookie-actions">
    <button class="blue-button-sm" (click)="setCookiePermission('Yes')">
      Accept Cookies
    </button>
    <button class="yellow-button-sm" (click)="setCookiePermission('No')">
      Decline Cookies
    </button>
  </div>
</div>
