import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

@Component({
  selector: 'app-cookie-permission',
  templateUrl: './cookie-permission.component.html',
  styleUrls: ['./cookie-permission.component.css'],
})
export class CookiePermissionComponent implements OnInit {
  isShowCookieModal: boolean = true;
  cookiePermission: string = '';

  constructor(private readonly localStorageService: LocalStorageService) {}

  ngOnInit() {
    this.cookiePermission =
      this.localStorageService.getLocalStorage('cookiePermission');
    console.log('cookie permission', this.cookiePermission);

    this.isShowCookieModal = !this.cookiePermission ? true : false;
  }

  public setCookiePermission(permission: 'Yes' | 'No') {
    this.localStorageService.setLocalStorage('cookiePermission', permission);

    this.isShowCookieModal = false;
  }
}
