import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing-layout-body',
  templateUrl: './marketing-layout-body.component.html',
  styleUrls: ['./marketing-layout-body.component.css']
})
export class MarketingLayoutBodyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
