<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="small"
  color="#fff"
  type="ball-circus"
  [fullScreen]="true"
  template="<img src='../../../../assets/images/icon/round_dot_loader_spinner.gif' />"
>
  <!-- <p style="color: white">Processing. Please Wait....</p> -->
</ngx-spinner>
<app-cookie-permission></app-cookie-permission>
<router-outlet></router-outlet>
