import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class I18nServiceService {
  localeEvent = new Subject<string>();

  constructor(private translate: TranslateService) {}

  public changeLanguage(language: string): void {
    this.translate.use(language);
    this.localeEvent.next(language);
  }
}
