import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketingLayoutBodyComponent } from './layout/marketing-layout/marketing-layout-body/marketing-layout-body.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { DashboardMenuComponent } from './layout/dashboard-layout/dashboard-menu/dashboard-menu.component';
import { authGuard } from './guards/auth.guard';
import { PublicLayoutBodyComponent } from './layout/public-layout/public-layout-body/public-layout-body.component';
import { superAdminGuard } from './guards/super-admin.guard';
import { ngoAdminGuard } from './guards/ngo-admin.guard';
import { SuperAdminMenuComponent } from './layout/super-admin-layout/super-admin-menu/super-admin-menu.component';

const routes: Routes = [
  {
    path: 'admin',
    component: SuperAdminMenuComponent,
    loadChildren: () =>
      import('./modules/super-admin/super-admin.module').then(
        (m) => m.SuperAdminModule
      ),
    canActivate: [authGuard, superAdminGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    component: DashboardMenuComponent,
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [authGuard, ngoAdminGuard],
  },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  {
    path: 'public',
    component: PublicLayoutBodyComponent,
    loadChildren: () =>
      import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '',
    component: MarketingLayoutBodyComponent,
    loadChildren: () =>
      import('./modules/marketing/marketing.module').then(
        (m) => m.MarketingModule
      ),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
